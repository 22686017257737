import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import BecomePartnerJson from "./become-partner.json"
import { PageSeo } from "../components/PageSeo"
import { Formik, Field, Form, ErrorMessage } from "formik"
import "./styles/company.scss"

const BecomePartnerTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const { name, details, banner } = markup.frontmatter
  return (
    <Layout>
      <section className="BecomePartnerTemplate">
        <Banner data={banner} />
        <h2 className="section_heading text-center py-3">{name}</h2>
        <BecomeapartnerForm details={details} />
      </section>
    </Layout>
  )
}

const BecomeapartnerForm = ({ details, BecomePartner }) => {
  return (
    <div className="becomepartnerform">
      <div className="container my-3">
        <h5 className="tab_heading">{details.description}</h5>

        <div className="partners mt-4">
          <h6 className="content">{details.heading}</h6>
          <ReactMarkdown
            className="texts"
            children={details.texts}
          ></ReactMarkdown>
        </div>

        <div className="partnerform mt-5">
          <h6 className="section_heading mb-4">PARTNER SIGNUP FORM</h6>
          <Formik
            initialValues={{
              partnerType: "",
              fullname: "",
              lastname: "",
              companyName: "",
              state: "",
              email: "",
              phone: "",
            }}
            validate={values => {
              const errors = {}
              if (!values.partnerType) {
                errors.partnerType = "Please Choose One"
              }

              if (!values.fullname) {
                errors.fullname = "Please Enter your First Name"
              } else if (values.fullname.length <= 4) {
                errors.fullname = "must be atleast 4 characters"
              }

              if (!values.lastname) {
                errors.lastname = "Please Enter your Last Name"
              } else if (values.lastname.length <= 4) {
                errors.lastname = "must be atleast 4 characters"
              }

              if (!values.state) {
                errors.state = "Please Choose State"
              }

              if (!values.email) {
                errors.email = "Please Enter Email ID"
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = "Invalid Email address"
              }

              if (!values.phone) {
                errors.phone = "Please Enter Phone Number"
              } else if (values.phone.length !== 10) {
                errors.phone = "Phone number must be 10 numbers"
              }
              return errors
            }}
            onSubmit={(data, actions) => {
              const encode = data => {
                return Object.keys(data)
                  .map(
                    key =>
                      encodeURIComponent(key) +
                      "=" +
                      encodeURIComponent(data[key])
                  )
                  .join("&")
              }
              console.table(data)
              fetch(
                "https://xcmt4mljo4.execute-api.us-west-2.amazonaws.com/prod/sendmail",
                {
                  method: "POST",
                  headers: { "Content-Type": "applecation/json;charset=utf-8" },
                  body: encode({ ...data }),
                }
              )
                .then(() => {
                  actions.resetForm()
                  alert(
                    "Thanks!. We have received your details for Partnership with us."
                  )
                })
                .catch(error => alert(error))
            }}
          >
            <div className="row">
              <div className="col-12 col-lg-7">
                <Form name="partners-form" className="row">
                  <Field type="hidden" name="partners"></Field>
                  <Field type="hidden" name="bot-fields"></Field>

                  <div className="col-12 mb-3">
                    <label htmlFor="partnerType">
                      {" "}
                      <b>Partner type *</b>{" "}
                    </label>
                    <Field
                      name="partnerType"
                      className="form-select mt-2"
                      as="select"
                    >
                      <option disabled defaultValue="">
                        - Choose Partner Type -
                      </option>
                      {BecomePartnerJson.partnertypes.length &&
                        BecomePartnerJson.partnertypes.map((item, index) => (
                          <option  key={`partnertypes${index}`}>{item.type}</option>
                        ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      className="text-danger text-small"
                      name="partnerType"
                    />
                  </div>

                  <div className="col-6 mb-3">
                    <label htmlFor="fullname">
                      {" "}
                      <b>Full Name *</b>{" "}
                    </label>
                    <Field
                      className="form-control mt-2"
                      name="fullname"
                      type="text"
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger text-small"
                      name="fullname"
                    />
                  </div>

                  <div className="col-6 mb-3">
                    <label htmlFor="lastname">
                      {" "}
                      <b>Last Name *</b>{" "}
                    </label>
                    <Field
                      className="form-control mt-2"
                      name="lastname"
                      type="text"
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger text-small"
                      name="lastname"
                    />
                  </div>

                  <div className="col-6 mb-3">
                    <label htmlFor="companyName">
                      {" "}
                      <b>Company Name</b>{" "}
                    </label>
                    <Field
                      className="form-control mt-2"
                      name="companyName"
                      type="text"
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger text-small"
                      name="companyName"
                    />
                  </div>

                  <div className="col-6 mb-3">
                    <label htmlFor="state">
                      {" "}
                      <b>State *</b>{" "}
                    </label>
                    <Field
                      name="state"
                      className="form-select mt-2"
                      as="select"
                    >
                      <option disabled defaultValue="">
                        - Choose Country -
                      </option>
                      {BecomePartnerJson.countrys.length &&
                        BecomePartnerJson.countrys.map((item, index) => (
                          <option key={`countrys${index}`} value={item.value}>{item.name}</option>
                        ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      className="text-danger text-small"
                      name="state"
                    />
                  </div>

                  <div className="col-6 mb-3">
                    <label htmlFor="email">
                      {" "}
                      <b>Email *</b>{" "}
                    </label>
                    <Field
                      className="form-control mt-2"
                      name="email"
                      type="text"
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger text-small"
                      name="email"
                    />
                  </div>

                  <div className="col-6 mb-3">
                    <label htmlFor="phone">
                      {" "}
                      <b>Number *</b>{" "}
                    </label>
                    <Field className="form-control mt-2" name="phone" />
                    <ErrorMessage
                      component="div"
                      className="text-danger text-small"
                      name="phone"
                    />
                  </div>

                  <div className="col-6 mb-3">
                    <button type="submit" className="btn btn-primary">
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                </Form>
              </div>

              <div className="col-12 col-lg-5 text-end">
                <GatsbyImage
                  image={details.image.childImageSharp.gatsbyImageData}
                  alt={"Hello Partner"}
                  className="rounded"
                />
              </div>
            </div>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export const BecomeapartnerQuery = graphql`
  query BecomeapartnerQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "become-partner" } }) {
      id
      html
      frontmatter {
        name
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        details {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          description
          heading
          texts
        }
      }
    }
  }
`

export default BecomePartnerTemplate
export const Head = () => (
  <PageSeo
    title="Become a Partner | INVESTMENT MANAGEMENT SOFTWARE SOLUTION PROVIDER"
    description="WITH CREDENCE ANALYTICS, PARTNERS CAN GET AHEAD BY REAPING MULTIPLE ADVANTAGES"
    keywords="In it to Win Mindset, Shared Goals and Expectations, Joint Business Planning, Partner Enablement, Industry Relevant Demos and POCs, Proposal Assistance"
  />
)
